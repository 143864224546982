import { ILayoutContextData, LayoutReducerActionType, ILayoutReducerAction } from '@/types/layout';

export const layoutReducer = (state: ILayoutContextData, action: ILayoutReducerAction) => {
  switch (action.type) {
    case LayoutReducerActionType.updateModalOpen:
      return {
        ...state,
        modalOpen: action.payload,
      };
    case LayoutReducerActionType.clear:
      return {};
    default:
      return state;
  }
};
