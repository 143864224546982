/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { FC, Fragment, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import { CustomComponentType } from '@/lib/cms/parsers/butter/utils/componentConverter';

import { ContactInfo, GenericHtml, Media, RichText, Subtitle, TextBlock, Title } from '@lamesarv-sdk/components';
import { Header, IComponentHeader } from '@/components/Header/Header';
import { EventList, IComponentEventsList } from '@/components/EventsList/EventsList';
import { IComponentVenuesList, VenuesList } from '@/components/VenuesList/VenuesList';

import {
  ComponentType,
  IComponent,
  IComponentBasicContent,
  IComponentContactInfo,
  IComponentLayoutStart,
  IComponentSubtitle,
  IComponentTitle,
  IPage,
} from '@lamesarv-sdk/types';

class PageLayoutColumnsComponent {
  public componentList: React.ReactElement[] = [];

  private columnsTypeMap: { [key: string]: string } = {
    'columns-1': 'px-2 w-full',
    'columns-2': 'px-2 w-full md:w-1/2',
    'columns-3': 'px-2 w-full md:w-1/3',
    'columns-4': 'px-2 w-full md:w-1/2 lg:w-1/4',
  };
  private columnsClass: string;

  constructor(columnsType: string) {
    this.columnsClass = this.columnsTypeMap[columnsType];
  }

  public getComponent(baseIndex: number) {
    return (
      <div key={baseIndex} className="flex flex-row flex-wrap -mx-2 mb-5">
        {this.componentList.map((component, index) => {
          return (
            <div key={`${baseIndex}-${index}`} className={`${this.columnsClass} ${component.props.extraClasses ?? ''}`}>
              {component}
            </div>
          );
        })}
      </div>
    );
  }
}

type PageLayoutComponent = PageLayoutColumnsComponent;

export const TailwindComponent = () => {
  return <div className="px-2 w-full md:w-1/2 md:w-1/3 md:w-1/2 lg:w-1/4" />;
};

export const parsePageComponents = (apiComponents: IComponent[], pageData?: IPage) => {
  const serverComponents: Partial<Record<ComponentType, FC<any>>> = {
    [ComponentType.genericHtml]: GenericHtml,
    [ComponentType.basicImage]: Media,
    [ComponentType.subtitle]: (apiComponent: IComponentSubtitle) => (
      <Subtitle {...apiComponent} className={twMerge('text-sage-700', apiComponent.className)} />
    ),
    [ComponentType.basicContent]: (apiComponent: IComponentBasicContent) =>
      apiComponent.content.reduce((acc, content, index) => {
        if (content.text) {
          acc.push(<TextBlock key={index} text={content.text} className={apiComponent.className} />);
        }
        if (content.richText) {
          acc.push(<RichText key={index} content={content.richText} className={apiComponent.className} />);
        }
        return acc;
      }, [] as ReactElement[]),
    [ComponentType.contactInfo]: (apiComponent: IComponentContactInfo) => (
      <ContactInfo classTitle="text-xl text-sage-700" classWrapper="pl-4" {...apiComponent} />
    ),
    [ComponentType.title]: (apiComponent: IComponentTitle) => (
      <Title {...apiComponent} className={twMerge('text-sage-800', apiComponent.className)} />
    ),
    [CustomComponentType.header as unknown as ComponentType]: (apiComponent: IComponentHeader) => (
      <Header {...apiComponent} seo={pageData.seo} />
    ),
    [CustomComponentType.eventsList as unknown as ComponentType]: (apiComponent: IComponentEventsList) => (
      <EventList {...apiComponent} />
    ),
    [CustomComponentType.venuesList as unknown as ComponentType]: (apiComponent: IComponentVenuesList) => (
      <VenuesList {...apiComponent} />
    ),
  };

  const pageComponents: React.ReactElement[] = [];
  const pageComponentLayout: PageLayoutComponent[] = [];
  apiComponents?.forEach((apiComponent, index) => {
    if (apiComponent.componentType == ComponentType.layoutStart) {
      pageComponentLayout.push(
        new PageLayoutColumnsComponent((apiComponent as IComponentLayoutStart).structure as string),
      );
    } else if (apiComponent.componentType == ComponentType.layoutEnd) {
      const _pageComponent = pageComponentLayout.pop();
      pageComponents.push(_pageComponent ? _pageComponent.getComponent(index) : <Fragment />);
    } else {
      const pageComponentsList = pageComponentLayout.length
        ? pageComponentLayout[pageComponentLayout.length - 1].componentList
        : pageComponents;

      const ServerComponent = apiComponent.componentType && serverComponents[apiComponent.componentType];
      if (ServerComponent) {
        pageComponentsList.push(<ServerComponent key={index} {...apiComponent} />);
      }
    }
  });

  return pageComponents;
};

interface ICmsPageProps {
  pageData: IPage;
}

export const CMSPage = (props: ICmsPageProps) => {
  const pageComponents = parsePageComponents(props.pageData.body, props.pageData);

  return <div className="w-full">{pageComponents.map((pageComponent) => pageComponent)}</div>;
};
